import React from 'react';
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Header from '../components/shared/header';
import CtaLink from '../components/substance/cta-link'; // TODO: move to shared
import styled from 'styled-components';
import Helmet from 'react-helmet';

const placeholderImageUrl = 'https://upload.wikimedia.org/wikipedia/commons/thumb/6/6c/No_image_3x4.svg/1280px-No_image_3x4.svg.png';

export default ({ data }) => {
  const { drug } = data;

  const renderSource = () => {
    if (!drug.organisation.name) return null;
    if (drug.organisation.name && drug.organisation.link) {
      return (
        <>
          <SectionHeader>Source</SectionHeader>
          <a href={drug.organisation.link} target="_blank" style={{ borderBottom: '1px solid #494949' }} className="externalLink">
            <InfoTextUnderlined>{`${drug.organisation.name}`}</InfoTextUnderlined>
          </a>
        </>
      );
    }
    return (
      <>
        <SectionHeader>Source</SectionHeader>
        <InfoText>{`${drug.organisation.name}`}</InfoText>
      </>
    );
  }

  const renderTestInfo = () => {
    const dateText = drug.date_tested ? ` on ${drug.date_tested}` : '';
    const city = drug.locationCity ? drug.locationCity : '';
    const country = drug.locationCountry ? `${drug.locationCity ? ', ' : ''}${drug.locationCountry}` : '';
    const locationText = drug.locationCity || drug.locationCountry ? ` in ${city}${country}` : '';
    return (
      <>
        <SectionHeader>Date and Location</SectionHeader>
        <InfoText>{`This pill has been tested${dateText}${locationText}.`}</InfoText>
      </>
    )
  }

  const renderSubstance = () => (
    <>
      <SectionHeader>Substance</SectionHeader>
      {drug.substance.map(substance => (
        <InfoText>{`${substance.amount ? `${substance.amount} ` : 'Unspecified amount '}`}{`of ${substance.name} ${substance.amount ? 'have' : 'has'} been identified in this pill`}</InfoText>
      ))}
      <CtaLink text="Check the risk of overdose for this pill in the KnowDrugs app" />
    </>
  )

  const renderSaferUse = () => (
    <>
      <SectionHeader>Safer use</SectionHeader>
      <ul>
        <InfoListItem>{`Similar-looking pills may still contain completely different substances and/or strengths`}</InfoListItem>
        <InfoListItem>{`Use drug testing services if possible`}</InfoListItem>
        <InfoListItem>{`Start with 1/3 of the pill and wait at least 2 hours to experience the effects`}</InfoListItem>
        <InfoListItem>{`Watch each other and talk to your friends if you’re feeling unwell`}</InfoListItem>
        <InfoListItem>{`Brand logos on ecstasy pills are always counterfeits and not related whatsoever to any trademarks`}</InfoListItem>
      </ul>
    </>
  )

  let socialMediaDescription = '';
  drug.substance.forEach(substance => {
    socialMediaDescription += `${substance.amount ? `${substance.amount} ` : 'Unspecified amount '}` + `of ${substance.name} ${substance.amount ? 'have' : 'has'} been identified in this pill. `;
  });
  return (
    <Layout>

      <Helmet>
        <title>KnowDrugs: {drug.name}</title>
        <meta property="og:description" content={socialMediaDescription} />
        <meta property="twitter:card" content="summary" />
        <meta property="twitter:description" content={socialMediaDescription} />
        <meta property="twitter:title" content={`KnowDrugs: ${drug.name}`} />
        <meta property="twitter:site" content="@know_drugs" />
        <meta property="twitter:image" content={drug.img_l || placeholderImageUrl} />
      </Helmet>

      <Header category="Warnings" title={drug.name} />
      <ImageContainer drug={drug} />

      {renderSubstance()}

      {renderTestInfo()}

      {renderSource()}

      {renderSaferUse()}

    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    drug(fields: { slug: { eq: $slug } }) {
      name
      date_tested
      locationCity
      locationCountry
      organisation {
        name
        link
      }
      img_l
      substance {
        name
        amount
      }
    }
  }
`

const ImageContainer = ({ drug, children }) => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      paddingTop: 48,
      paddingBottom: 48,
      backgroundImage: `url(${drug.img_l || placeholderImageUrl})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center'
    }}>
    <img
      src={drug.img_l || placeholderImageUrl}
      style={{ maxHeight: 300, margin: 0 }}
      alt={`${drug.name} molecular scheme`}
    />
  </div>

)

const SectionHeader = styled.h2`
  font-family: 'Inter';
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  color: #161616;
  margin-bottom: 24px;
  margin-top: 72px;
`

const InfoText = styled.p`
  font-family: 'Karla';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 150%;
  color: #494949;
`

const InfoTextUnderlined = styled.p`
  font-family: 'Karla';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 150%;
  color: #494949;
  text-decoration: underline;
  padding-bottom: 2px;
`

const InfoListItem = styled.li`
  font-family: 'Karla';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 150%;
  color: #494949;
`