import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  margin-bottom: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Category = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  letter-spacing: 2px;
  color: #6200EE;
  text-transform: uppercase;
`

const Title = styled.h1`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 94px;
  line-height: 104px;
  text-align: center;
  color: #161616;
  max-width: 100%
`;


const Header = ({ category, title, children }) => (
  <Container>
    <Category>{category}</Category>
    <Title className="headerTitle">{title}</Title>
    {children}
  </Container>
);

export default Header;