import React from 'react';
import styled from 'styled-components';

const CtaLinkText = styled.p`
  font-family: Karla;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 150%;
  text-decoration-line: underline;
  color: #494949;
`

export default ({text}) => (
  <a href='#footer'>
    <CtaLinkText>{text}</CtaLinkText>
  </a>
)